import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ArrowStairs from "../components/animations/ArrowStairs";
import ChairOrange from "../components/animations/ChairOrange";
import Check from "../components/animations/Check";
import GreenUnderline from "../components/animations/GreenUnderline";
import Lamp3 from "../components/animations/Lamp3";
import Lamp4 from "../components/animations/Lamp4";
import Plant1 from "../components/animations/Plant1";
import Plant2 from "../components/animations/Plant2";
import PlantBag from "../components/animations/PlantBag";
import Sofa2 from "../components/animations/Sofa2";
import SofaGreen from "../components/animations/SofaGreen";
import SofaWindow from "../components/animations/SofaWindow";
import ZigGreen from "../components/animations/ZigZagGreen";
import CTA from "../components/fragments/CTA";
import LineBackground from "../components/fragments/lineBackground";
import MetaData from "../components/fragments/MetaData";
import("../styles/global.scss");
import("../styles/sections.scss");
import ZigPurple from "../components/animations/ZigZagPurple";

// Define the slide-up effect variant
const slideUpVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

export const WhyJoin = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  useEffect(() => {
    const targetElement = document.querySelector(".whyJoinSplash_wrap");

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBannerVisible(!entry.isIntersecting);
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, []);

  return (
    <>
      {/* Set meta tags for the document */}
      <MetaData title="Why Join Therapy in London" />

      {/* Only show signBanner if user scrolls past the whyJoinSplash_wrap with fade effect */}
      <motion.div
        className="signBanner"
        animate={{ opacity: isBannerVisible ? 1 : 0 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        style={{ display: isBannerVisible ? "block" : "none" }}
      >
        <div className="content">
          <CTA type="ghost_blue" text="Login" link="/portal/signin" />
          <CTA type="blue" text="Create Account" link="/portal/signup" />
        </div>
      </motion.div>

      <section className="topPadding">
        <div className="whyJoinSplash_wrap">
          <div className="whyJoinSplash_title">
            <div className="row">
              <div className="column">
                <Plant1 />
              </div>
              <motion.div
                className="column text"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <label>Find real connections. Spark real change.</label>
                <h1>
                  Why Join Therapy&nbsp;
                  <span className="zigWrap">
                    in London?
                    <ZigPurple />
                  </span>
                </h1>
                <p>
                  Finding the right therapist is a brave yet daunting process
                  for your clients. Thousands of therapists are currently
                  advertising their practice in London.
                </p>
                <p>
                  We aim to foster lasting transformations both for your clients
                  and your practice.
                </p>
              </motion.div>
              <div className="column">
                <Lamp4 />
              </div>

            </div>

            <div className="therapistSign_mobile">
              <div
                className="search_wrap_location"
                initial="hidden"
                animate="visible"
              >
                <div className="searchMask"></div>
                <div className="search_2">
                  <h2>I'm a Therapist</h2>
                  <div className="search_input_wrap">

                  </div>
                </div>
                <div className="therapistSign">
                  <CTA type="ghost_white" text="Login" link="/portal/signin" />
                  <CTA type="ghost_white" text="Create Account" link="/portal/signup" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="standOut_wrap">
          <motion.div
            className="title"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={slideUpVariant}
          >
            <h2>
              You all deserve  <br />to{" "}
              <span className="zigWrap">
                stand out
                <ZigGreen />
              </span>
            </h2>
            <div className="sofa">
              <Sofa2 />
            </div>

          </motion.div>
          <div className="row">
            <div className="column">
              <motion.div
                className="block"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <Check />
                <p>
                  A successful therapy practice starts with a strong therapeutic
                  bond with your clients. We want to help you stand out as the
                  <span>
                    &nbsp; unique therapist&nbsp;
                    <GreenUnderline />
                  </span>
                  that you are.
                </p>
              </motion.div>
              <motion.div
                className="block"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <p>
                  At Therapy in London, we believe that every client deserves to{" "}
                  <span>
                    connect
                    <GreenUnderline />
                  </span>{" "}
                  with the best therapist for their needs, and this is at the
                  very core of what we do.
                </p>
              </motion.div>
              <div className="imgWrap">
                <Plant2 />
              </div>
            </div>

            <div className="column">
              <motion.div
                className="block"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <p>
                  We understand the frustrations you as a therapist encounter when trying to expand your practice, connect with clients, and find fulfilment in your work.
                </p>
              </motion.div>
              <motion.div
                className="block"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={slideUpVariant}
              >
                <p>
                  You can now showcase your practice right here on the Therapy
                  in London platform. Sign up to start creating a solid
                  foundation for you to build long term and successful
                  connections with clients.
                </p>
                <ArrowStairs />
              </motion.div>
            </div>
          </div>

        </div>
        <div className="signUp_Wrap">
          <motion.div
            className="content"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={slideUpVariant}
          >
            <CTA
              type="blue"
              text="Sign Up for Free"
              link="../portal/signup"
            />
          </motion.div>
        </div>
      </section>

      <section style={{ paddingTop: "0" }}>
        <div className="whyJoinSplash_wrap" style={{ marginTop: "0" }}>
          <motion.div
            className="whyJoin_latestArticles"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={slideUpVariant}
          >
            <div className="title">
              <div>
                <h2>By joining Therapy in London, you’ll receive:</h2>
              </div>
              <SofaWindow />
            </div>

            <div className="whyJoin_wrap">
              <div className="why">
                <div className="column">
                  <motion.div
                    className="listWrap"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                    variants={slideUpVariant}
                  >
                    <span>
                      <img src="../images/illustrations/dashGreen.svg" />
                    </span>
                    <p>
                      An adaptable and intuitive profile to showcase who you
                      really are. Our platform offers multiple options to create
                      a unique profile that advertises your practice in your own
                      way.
                    </p>
                  </motion.div>

                  <motion.div
                    className="listWrap"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                    variants={slideUpVariant}
                  >
                    <span>
                      <img src="../images/illustrations/dashGreen.svg" />
                    </span>
                    <p>
                      CPD courses (Online and face-to-face) on trending topics.
                    </p>
                  </motion.div>

                  <motion.div
                    className="listWrap"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                    variants={slideUpVariant}
                  >
                    <span>
                      <img src="../images/illustrations/dashGreen.svg" />
                    </span>
                    <p>
                      Opportunities to showcase your profile in the search
                      results by voicing your thoughts on important topics.
                    </p>
                  </motion.div>

                  <motion.div
                    className="listWrap"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                    variants={slideUpVariant}
                  >
                    <span>
                      <img src="../images/illustrations/dashGreen.svg" />
                    </span>
                    <p>
                      Marketing opportunities to make your practice stand out
                      through written and video content.
                    </p>
                  </motion.div>

                  <motion.div
                    className="listWrap"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                    variants={slideUpVariant}
                  >
                    <span>
                      <img src="../images/illustrations/dashGreen.svg" />
                    </span>
                    <p>
                      Discounted workshops on how to grow your practice as well
                      as specific discussions on culture, modern society, and
                      generational issues. (Online and face-to-face)
                    </p>
                  </motion.div>

                  <motion.div
                    className="listWrap"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                    variants={slideUpVariant}
                  >
                    <span>
                      <img src="../images/illustrations/dashGreen.svg" />
                    </span>
                    <p>
                      Direct support from Therapy in London team to assist you
                      with your membership and profile.
                    </p>
                  </motion.div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      <div className="howTo_wrap">
        <motion.div
          className="title"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={slideUpVariant}
        >
          <h2>How to Join</h2>
        </motion.div>

        <div className="howTo_Steps">
          <div className="row">
            <motion.div
              className="rowBlock"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
              variants={slideUpVariant}
            >
              <div className="column">
                <div className="blockWrap">
                  <img className="number" src="../images/numbers/1.svg" />
                  <div className="text">
                    <h2>Sign Up</h2>
                    <p>
                      Create your free account and start exploring your exciting
                      new practitioner portal.
                    </p>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="blockWrap">
                  <PlantBag />
                </div>
              </div>
            </motion.div>
          </div>
          <div className="row">
            <motion.div
              className="rowBlock changeOrder"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
              variants={slideUpVariant}
            >
              <div className="column">
                <div className="blockWrap">
                  <SofaGreen />
                </div>
              </div>

              <div className="column">
                <div className="blockWrap">
                  <img className="number" src="../images/numbers/2.svg" />
                  <div className="text">
                    <h2>Build your Profile</h2>
                    <p>
                      Our unique, adaptable and intuitive profiles allow you to
                      share a side of yourself that will draw your ideal clients
                      to you. (Your profile will not be public until you
                      continue to Step 3)
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="row">
            <motion.div
              className="rowBlock"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
              variants={slideUpVariant}
            >
              <div className="column">
                <div className="blockWrap">
                  <img className="number" src="../images/numbers/3.svg" />
                  <div className="text">
                    <h2>Upload your Documentation</h2>
                    <p>
                      Once you upload your qualifications, registered body and
                      insurance, we will review and approve your public account.
                      To approve your profile, we require proof of both of the
                      following: Your insurance certificate and Registration
                      number with your mental health body.
                    </p>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="blockWrap">
                  <Lamp3 />
                </div>
              </div>
            </motion.div>
          </div>
          <div className="row">
            <motion.div
              className="rowBlock changeOrder"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
              variants={slideUpVariant}
            >
              <div className="column">
                <div className="blockWrap">
                  <ChairOrange />
                </div>
              </div>

              <div className="column">
                <div className="blockWrap">
                  <img className="number" src="../images/numbers/4.svg" />
                  <div className="text">
                    <h2>Approval</h2>
                    <p>
                      The review process can take up to 72 hours*. Once
                      approved, you will be able to activate your paid
                      subscription.
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="pricesWrap overflowHidden" id="pricing">
        <LineBackground />

        <motion.div
          className="prices"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={slideUpVariant}
        >
          <div className="title">
            <h2>Practitioner profile costs</h2>
          </div>

          <div className="pricesBlocks">
            <div className="block">
              <label className="price">£15 </label>
              <label className="period">per month </label>
              <label className="smallPrint">inc.VAT</label>
            </div>
            <div className="block">
              <label className="price">£165 </label>
              <label className="period">per year </label>
              <label className="period2">(1 month free)</label>
            </div>
          </div>
        </motion.div>
      </div>

      <div className="signUp_Wrap">
        <motion.div
          className="content"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={slideUpVariant}
        >
          <CTA
            type="cta_ghost"
            text="Sign Up for Free"
            link="../portal/signup"
          />
          <p>
            *Profiles and paid memberships will only be activated once
            qualifications, insurance documents and proof of body membership are
            provided and reviewed.
          </p>
        </motion.div>
      </div>
    </>
  );
}
