import React, { useEffect, useRef } from "react";
const loadLottie = import("lottie-web"); // Dynamic import of lottie-web

const Lamp5 = () => {
  const lottieRef = useRef(null); // Reference for the Lottie container
  const animationInstance = useRef(null); // Store the Lottie instance

  useEffect(() => {
    let handleScroll;

    loadLottie.then((module) => {
      const lottie = module.default;

      // Initialize the Lottie animation
      const lottiePlayer = lottie.loadAnimation({
        container: lottieRef.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        path: "/images/animations/lamps/lamp5.json",
      });

      animationInstance.current = lottiePlayer; // Save the instance

      // Stop the animation when it's loaded
      lottiePlayer.addEventListener("DOMLoaded", () => {
        lottiePlayer.stop();
      });

      // Handle scroll to control animation frames
      handleScroll = () => {
        if (!animationInstance.current) return;
        const rect = lottieRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        const elementTop = rect.top + window.scrollY - lottieRef.current.offsetParent.offsetTop;
        const elementHeight = rect.height;
        const buffer = windowHeight * 0.25;
        const start = elementTop - windowHeight + buffer;
        const end = elementTop + elementHeight + buffer;

        const scrollY = window.scrollY;
        const progress = Math.min(Math.max((scrollY - start) / (end - start), 0), 1);

        const maxFrames = lottiePlayer.totalFrames;
        const frame = Math.floor(progress * maxFrames);

        lottiePlayer.goToAndStop(frame, true);
      };

      // Observe when the element enters or exits the viewport
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            window.addEventListener("scroll", handleScroll);
            handleScroll();
          } else {
            window.removeEventListener("scroll", handleScroll);
          }
        });
      }, { threshold: 0.1 });

      observer.observe(lottieRef.current);

      // Cleanup function
      return () => {
        window.removeEventListener("scroll", handleScroll);
        observer.disconnect();
        animationInstance.current?.destroy();
      };
    });
  }, []);

  return (
    <div
      ref={lottieRef}
      style={{ width: "400px", height: "450px" }}
      className="lamp"
    />
  );
};

export default Lamp5;
