import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
    defaultOptions: {
    queries: {
      retry: 3,
      retryDelay: attemptIndex => Math.min(120000 * 2 ** attemptIndex, 240000),
    },
  },
});



